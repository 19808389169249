// import Vue from 'vue'
import { Component } from "vue-property-decorator";
import { UserLoginModel, UserLoggedInfoModel } from "@/models/userModels";
import { mapActions, mapGetters } from "vuex";
import axios, { AxiosPromise } from "axios";
import baseContextComponent from "@/components/baseContextComponent";
import api from "@/services/api";

@Component({
	computed: {...mapGetters(["getUser", "getIsAuthenticated"])},
	methods: { ...mapActions(["loginUser", "loadPopCarrelloUser"]) }
})
export default class loginPage extends baseContextComponent {
	showPwd: boolean = false;
	isLoading: boolean = false;
	automaticLogin: boolean = false;
	user: UserLoginModel = new UserLoginModel();
	getUser!: UserLoggedInfoModel;
	loginUser!: (token: any) => Promise<UserLoggedInfoModel>;
	loadPopCarrelloUser!: (token: any) => Promise<boolean>;
	emailRules = [
		(v: string) => {
			return !!v || "Campo obbligatorio";
		},
		(v: string | null) =>
			v == null || /.+@.+/.test(v) || "Formato non valido"
	];
	passwordRules = [(v: string | null) => !!v || "Campo obbligatorio"];
	pathLogo: string = null;

	get defaultReturnPath() { return this.$route.query.returnUrl || "/carrello"} ;
	created() {
		var self = this;
		self.automaticLogin = true;
		if (self.$route.query.tk) {

			var token = self.$route.query.tk;

			let path = self.defaultReturnPath;
			self.loginUser(token).then(() => {
				if (self.getUser.mustPay || self.getUser.segnalazionePagamentoFineMese) {
					self.$router.push({ name: "myoverins_tuoiPagamenti"});
					return;
				}
				self.$router.push({ path: path as string });
			}).catch(err => self.automaticLogin = false);
		}
		else
			self.automaticLogin = false;


		var associazioneID = this.$route && this.$route.query && this.$route.query.id
			? Number.parseInt(( this.$route.query as any).id) : null;

		if( associazioneID && associazioneID > 0){
			api.get(api.endpoint.AGENZIE.ASSOCIAZIONI + "/" + associazioneID).then(res => {
				if( res && res.pathLogo ) {
					self.pathLogo = res.pathLogo;
				} 
			});
		}
	}

	checkForm(e: any) {
		var instance = this;
		e.preventDefault();
		instance.isLoading = true;
		api.doJwtLogin(instance.user)
			.then(token => {
				let path = instance.defaultReturnPath;
				instance.loginUser(token).then(() => {
					if (instance.getUser.mustPay || instance.getUser.segnalazionePagamentoFineMese) {
						instance.$router.push({ name: "myoverins_tuoiPagamenti"});
						return;
					}
					instance.$router.push({ path: path as string });
				});
				instance.isLoading = false;
			})
			.catch(error => {
				console.error(error);
				instance.isLoading = false;
			});
	}
	get headerText(): string {
		return this.$appConfig.loginPage.headerText;
	}
	get htmlText(): string {
		return this.$appConfig.loginPage.htmlText;
	}
}
